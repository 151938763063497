import React from 'react'
import { Row, Col, Card } from 'antd'

// const images = require('../../assets/devices/*.jpg')
// import * as images from '../../assets/devices'

const { Meta } = Card

export default function Device() {
  let images = []
  require
    .context('../../assets/devices', false, /\.jpg$/)
    .keys()
    .forEach(item => {
      images.push({
        url: item.replace('./', ''),
        name: item.substring(2, item.length - 4)
      })
    })
  return (
    <div className="mt-16">
      <h1>生产设备</h1>
      <Row>
        {images.map((image, idx) => {
          return (
            <Col span={8} className="mt-16 mb-16" key={idx}>
              <Card
                hoverable
                style={{ width: 300 }}
                cover={
                  <img
                    height="300"
                    alt={image.name}
                    src={require(`../../assets/devices/${image.url}`)}
                  />
                }>
                <Meta title={image.name} />
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
