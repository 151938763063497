import React from 'react'
import { FAQs as data } from '../../dataSource'

export default function FAQs() {
  return (
    <div className="mt-16">
      <h1>常见问题</h1>
      {data.map(item => {
        return (
          <div key={item.id}>
            <h1 className="mt-16">{item.question}</h1>
            <div dangerouslySetInnerHTML={{ __html: item.answer }} />
          </div>
        )
      })}
    </div>
  )
}
