import React, { useState } from 'react'
import { Layout, Carousel, Menu, Row, Col } from 'antd'
import { Route, withRouter } from 'react-router-dom'
import Home from '../pages/Home'
import News from '../pages/News/News'
import NewsDetail from '../pages/News/NewsDetail'
import FAQs from '../pages/News/FAQs'
import Product from '../pages/Products/Product'
import ProductDetail from '../pages/Products/Detail'
import Device from '../pages/Device/Device'
import About from '../pages/About/About'
import Factory from '../pages/About/Factory'
import Culture from '../pages/About/Culture'
import Message from '../pages/Contact/Message'
import Address from '../pages/Contact/Address'

import '../layout/layout.less'

const { Header, Footer, Content } = Layout
const { SubMenu } = Menu

const BaiscLayout = props => {
  const [current, SetCurrent] = useState('/')
  const { history, location } = props
  const handleClick = e => {
    SetCurrent(e.key)
    history.push(e.key)
  }

  const showTitle = () => {
    if (location.pathname && location.pathname.includes('/product')) {
      return '产品中心'
    }
    switch (location.pathname) {
      case '/about':
        return '关于我们'
      case '/culture':
        return '企业文化'
      case '/device':
        return '生产设备'
      case '/address':
        return '联系地址'
      case '/message':
        return '在线留言'
      case '/news':
        return '新闻中心'
      case '/faq':
        return '常见问题'
      case '/product':
        return '产品中心'
      case '/photos':
        return '企业实景'
      default:
        return ''
    }
  }
  return (
    <Layout className="layout">
      <Header className="header-wrapper">
        <div className="header">
          <div className="logo">
            <img
              src={require('../assets/images/logo.jpg')}
              alt="无锡市汇源机械科技有限公司"
            />
          </div>
          <Menu
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
            className="menu">
            <Menu.Item key="/home">首页</Menu.Item>
            <SubMenu
              title={<span className="submenu-title-wrapper">新闻中心</span>}>
              <Menu.Item key="/news">新闻</Menu.Item>
              <Menu.Item key="/faq">常见问题</Menu.Item>
            </SubMenu>
            <SubMenu
              title={<span className="submenu-title-wrapper">产品展示</span>}>
              {/* <Menu.Item key="/product/XiaoZhou">销轴</Menu.Item> */}
              <Menu.Item key="/product/DianJi">电机铸件类</Menu.Item>
              <Menu.Item key="/product/JiaDian">家电铸件类</Menu.Item>
              <Menu.Item key="/product/QiChe">汽车铸件类</Menu.Item>
              {/* <Menu.Item key="/product/QiCheHouShiJing">
                汽车后视镜支座
              </Menu.Item> */}
              <Menu.Item key="/product/DengJu">灯具</Menu.Item>
            </SubMenu>
            <Menu.Item key="/device">生产设备</Menu.Item>
            <SubMenu
              title={<span className="submenu-title-wrapper">关于我们</span>}>
              <Menu.Item key="/about">企业简介</Menu.Item>
              <Menu.Item key="/culture">企业文化</Menu.Item>
              <Menu.Item key="/photos">企业实拍</Menu.Item>
            </SubMenu>
            <SubMenu
              title={<span className="submenu-title-wrapper">联系方式</span>}>
              <Menu.Item key="/address">联系电话地址</Menu.Item>
              <Menu.Item key="/message">在线留言</Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </Header>
      <Content>
        <div className="mt-16">
          {['/home', '/'].includes(location.pathname) && (
            <Carousel autoplay>
              <img src={require('../assets/images/nav1.jpg')} alt="导航图1" />
              <img src={require('../assets/images/nav2.jpg')} alt="导航图2" />
              <img src={require('../assets/images/nav3.jpg')} alt="导航图3" />
            </Carousel>
          )}
          {location.pathname !== '/home' && location.pathname !== '/' && (
            <div className="banner">
              <div className="container">
                <Row>
                  <Col>
                    <span className="text">{showTitle()}</span>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
        <div className="content-wrapper">
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/news" component={News} />
          <Route exact path="/newsDetail/:id" component={NewsDetail} />
          <Route exact path="/faq" component={FAQs} />
          <Route path="/product" component={Product} />
          <Route path="/productDetail" component={ProductDetail} />
          <Route exact path="/device" component={Device} />
          <Route exact path="/about" component={About} />
          <Route exact path="/photos" component={Factory} />
          <Route exact path="/culture" component={Culture} />
          <Route exact path="/address" component={Address} />
          <Route exact path="/message" component={Message} />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        苏ICP备19054139号 Copyright © 无锡市汇源机械科技有限公司{' '}
        {new Date().getFullYear()}
      </Footer>
    </Layout>
  )
}

export default withRouter(BaiscLayout)
