import React from 'react'
import { Row, Col } from 'antd'

export default function Address() {
  return (
    <div className="mt-16">
      <Row gutter={16}>
        <Col span={6}>
          <h2>联系电话地址</h2>
          <p>
            <span>无锡市汇源机械科技有限公司</span>
          </p>
          <p>
            <span>赵 &nbsp;总：13801519880&nbsp;</span>
          </p>
          <p>
            <span>电 &nbsp;话：0510-88788590</span>
          </p>
          <p>
            <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 0510-88789850</span>
          </p>
          <p>
            <span>传 &nbsp;真：0510-88782188</span>
          </p>
          <p>
            <span>Email:zhaoyu952333@126.com</span>
          </p>
          <p>
            <span>地 &nbsp;址：无锡市锡北工业园区泾瑞路15号</span>
          </p>
          <p>
            <br />
          </p>
        </Col>
        <Col span={18}>
          <iframe
            style={{ border: 'none' }}
            title="map"
            width="100%"
            height="600px"
            src="http://j.map.baidu.com/5c/AQK"
          />
        </Col>
      </Row>
    </div>
  )
}
