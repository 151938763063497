export const NEWS = [
  {
    id: 1,
    title: '压铸模具表面处理技术新突破评述',
    intro:
      '压铸模具是模具中的一个大类，模具压力加工是机械制造的重要组成部分，而模具的水平、质量和寿命则与模具表面强化技术休戚相关。随着科学技术的进步，近年来各种模具表面处理技术出现较大的进展...',
    content: `
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;压铸模具是模具中的一个大类，模具压力加工是机械制造的重要组成部分，而模具的水平、质量和寿命则与模具表面强化技术休戚相关。随着科学技术的进步，近年来各种模具表面处理技术出现较大的进展。鉴于表面处理是提高压铸模具寿命的重要手段之一，因此要提高我国压铸模具生产整体水平，表面处理技术将起着举足轻重的作用。</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 随着我国汽车摩托车工业的迅速发展，压铸行业迎来了发展的新时期。同时，也对压铸模具的综合力学性能、寿命等提出了更高的要求。要满足不断提高的使用性能需求仅仅依靠新型模具材料的应用仍然很难满足，必须将各种表面处理技术应用到压铸模具的表面处理当中才能达到对压铸模具高效率、高精度和高寿命的要。在各种模具中，压铸模具的工作条件是较为苛刻的。压力铸造是使熔融金属在高压、高速下充满模具型腔而压铸成型，在工作过程中反复与炽热金属接触，因此要求压铸模具有较高的耐热疲劳、导热性耐磨性、耐蚀性、冲击韧性、红硬性、良好的脱模性等。</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 因此，对压铸模具的表面处理技术要求较高近年来，各种压铸模具表面处理新技术不断涌现，但总的来说可以分为以下三个大类:(1)传统热处理工艺的改进技术：(2)表面改性技术，包括表面热扩渗处理、表面相变强化、电火花强化技术等：(3)涂镀技术，包括化学镀等。</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 1、传统热处理工艺的改进技术</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 传统的压铸模具热处理工艺是淬火-回火，以后又发展了表面处理技术。由于可作为压铸模具的材料多种多样，同样的表面处理技术和工艺应用在不同的材料上会产生不同的效果。史可夫最近提出针对模具基材和表面处理技术的基材预处理技术，在传统工艺的基础上，对不同的模具材料提出适合的加工工艺，从而改善模具性能，提高模具寿命。热处理技术改进的另一个发展方向，是将传统的热处理工艺与先进的表面处理工艺相结合，提高压铸模具的使用寿命。如将化学热处理的方法碳氮共渗，与常规淬火、回火工艺相结合的NQN(即碳氮共渗-淬火-碳氮共渗)复合强化，不但得到较高的表面硬度，而且有效硬化层深度增加、渗层硬度梯度分布合理、回火稳定性和耐蚀性提高，从而使得压铸模具在获得良好心部性能的同时，表面质量和性能大幅提高。</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 2、表面改性技术</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 2.1 表面热扩渗技术</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 这一类型中包括有渗碳、渗氮、渗硼以及碳氮共渗、硫碳氮共渗等。</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 2.1.1 渗碳和碳氮共渗</span>
    </p>
    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255);">
        <br/>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp; &nbsp; 渗碳工艺应用于冷、热作和塑料模具表面强化中，都能提高模具寿命。如3Cr2W8V钢制的压铸模具，先渗碳、再经1140～1150℃淬火，550℃回火两次，表面硬度可达HRC56～61，使压铸有色金属及其合金的模具寿命提高1.8～3.0倍。进行渗碳处理时，主要的工艺方法有固体粉末渗碳、气体渗碳、以及真空渗碳、离子渗碳和在渗碳气氛中加入氮元素形成的碳氮共渗等。其中，真空渗碳和离子渗碳则是近20年来发展起来的技术，该技术具有渗速快、渗层均匀、碳浓度梯度平缓以及工件变形小等特点，将会在模具表面尤其是精密模具表面处理中发挥越来越重要的作用。</span>
    </p>
    `,
    createdTime: '2019-09-07'
  },
  {
    id: 2,
    title: '铝压铸件检验规则',
    intro: '化学成分...',
    content: `
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
    <span style="font-size: 14px;"><strong>化学成分</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;">　　<strong>1.1 铝合金化学成分的检验方法，检验规则和复检应符合GB/T15115的规定。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　1.2 化学成分的试样也可取自压铸件，但必须符合GB/T15115的规定。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　2 力学性能</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　2.1 力学性能的检验方法，检验频率和检验规则应符合GB/T15115的规定。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　2.2 采用压铸件本体为试样时，切取部位的尺寸、测试形式由供需双方商定。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　3 压铸件几何尺寸的检验可按检验批量抽检或按GB2828、GB2829的规定进行，检验结果应符合本标准3.3的规定。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); line-height: normal; margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　4 压铸件表面质量的出厂检验应逐件检查，检验结果应符合本标准的规定。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　5 压铸件表面粗糙度按GB/T6060.1的规定执行。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　6 压铸件需抛光加工的表面按GB/T 6060.4的规定执行。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　7 压铸件需喷丸、喷沙加工的表面按GB/T 6060.5的规定执行。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　8 压铸件内部质量的试验方法及检验规则可以包括：X射线照片、无损探伤试验、金相图片和压铸件剖面等，其检验结果应符合本标准3.4.6的规定。</strong></span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
        <span style="font-family: 宋体, SimSun; font-size: 14px;"><strong>　　9 其它试验方法及检验规则按GB/T15114的规定执行。</strong></span>
    </p>
    <p>
        <br/>
    </p>
    `,
    createdTime: '2019-09-07'
  },
  {
    id: 3,
    title: '铝压铸件的详细介绍',
    intro:
      '铝压铸件是一种压力铸造的零件，是使用装好铸件模具的压力铸造机械压铸机，将加热为液态的铝或铝合金浇入压铸机的入料口，经压铸机压铸，铸造出模具限制的形状和尺寸的铝零件或铝合金零件...',
    content: `
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 15px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 铝压铸件是一种压力铸造的零件，是使用装好铸件模具的压力铸造机械压铸机，将加热为液态的铝或铝合金浇入压铸机的入料口，经压铸机压铸，铸造出模具限制的形状和尺寸的铝零件或铝合金零件，这样的零件通常就被叫做铝压铸件。铝压铸件在不同的地方有不同的叫法，如铝压铸零件、压铸铝零件、压铸铝件、压铸铝、铝压铸件、铝合金压铸零件等。</span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 15px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">　　由于金属铝及铝合金具有很好的流动性和可塑性，而且铸造加工是在有压力的压铸机中铸造，因此铝压铸件可以做出各种较复杂的形状，也可作出较高的精度和光洁度，从而很大程度的减少了铸件的机械加工量和金属铝或铝合金的铸造余量，不仅节约了电力、金属材料、还大大节约了劳动成本；而铝及铝合金具有优良的导热性，较小的比重和高可加工性；从而铝压铸件被广泛应用于汽车制造、内燃机生产、摩托车制造、电动机制造、油泵制造、传动机械制造、精密仪器、园林美化、电力建设、建筑装饰等各个行业。</span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 15px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">　　铝压铸件可以被制造为铝压铸汽车配件、铝压铸汽车发动机管件、铝压铸发动机气缸、铝压铸汽油机气缸缸盖、铝压铸气门摇臂、铝压铸气门支座、铝压铸电力配件、铝压铸电机端盖、铝压铸壳体、铝压铸泵壳体、铝压铸建筑配件、铝压铸装饰配件、铝压铸护栏配件、铝压铸铝轮等等零件。</span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 15px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;">　　不锈钢丸广泛应用于有色金属压铸件、浇铸件，铝型材、汽车零部件、机械制造业、五金、泵阀行业的表面处理。主要集中于去产品表面氧化皮、边缘表面毛刺、表面粗糙化、亚光郊果、平整强化、除锈处理。　不锈钢丸俗称不锈钢丝切丸，采用拉丝、切割、抛圆等工艺精制而成，外观光亮无锈，圆珠状（切丸，圆柱状）。　不锈钢丸硬度适中，成份精纯，覆盖面大，由于本身没有一般铸钢丸的气孔、异型等缺点，它的使用寿命更为长久，该产品性能完全可以替代进口产品，而价格大幅度低于进口产品，为客户节省成本。用不锈钢丸处理后铸件表面光洁不生锈，无须进行酸洗等后处理，有利于环境保护。您可以选择经过预抛的磨圆切丸和未经预抛的切丸，两种不同形状的产品。</span>
    </p>
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 5px;">
        <span style="font-size: 14px; font-family: 宋体, SimSun;"></span><br/>
    </p>
    `,
    createdTime: '2019-09-07'
  },
  {
    id: 4,
    title: '铝铸件铸件产品详解',
    intro:
      '二气孔气泡缺陷特征：三铝铸件 壁内气孔一般呈圆形或椭圆形 ，具有光滑的表面，一般是发亮的氧化皮，有时呈油黄色。',
    content: `
    <p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二气孔气泡缺陷特征：三铝铸件 壁内气孔一般呈圆形或椭圆形 ，具有光滑的表面，一般是发亮的氧化皮，有时呈油黄色。</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">　　表面气孔、气泡可通过喷砂发现，内部气孔气泡可通过X光 透视或机械加工发现气孔气泡在X光底片上呈黑色产生原因：</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.浇注合金不平稳，卷入气体</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.型(芯)砂中混入有机质(如煤屑、草 根马粪等)&nbsp;</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.铸型和砂芯通气不良</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.冷铁表面有缩孔</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.浇注系统设计不良</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">　　防止方法：</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.正确掌握浇注速度，避免卷入气 体。</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.型(芯)砂中不得混入有机杂质以减少造型材料的发气量</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.改善(芯)砂的排气能力</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.正确选用及处理冷铁</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.改进浇注系统设 计</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">　　三缩松缺陷特征铝铸件 缩松一般产生在内浇道附近飞冒口根部厚大部位、壁的厚薄转接处和具有大平面的薄壁处。&nbsp;</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">　　在铸态时断口为灰色，浅黄色经热处理后为灰白浅黄或灰黑色在x光底片上呈云雾状严重的呈丝状缩松可通过X光、荧光 低倍断口等检查方法发现产生原因：</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.冒口补缩作用差</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.炉料含气量太多</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.内浇道附近过热</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.砂型水分过多，砂芯未烘干</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.合金 晶粒粗大</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.铝铸件 在铸型中的位置不当</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.浇注温度过高，浇注速度太快</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;　防止方法：</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.从冒口补浇金属液，改进冒口设计</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 炉料应清洁无腐蚀</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.铸件缩松处设置冒口，安放冷铁或冷铁与冒口联用</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.控制型砂水分，和砂芯干燥</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.采取细化品粒的措施</span>
</p>
<p style="margin-top: 0px; padding: 0px; color: rgb(102, 102, 102); font-family: 微软雅黑, Arial, Helvetica, sans-serif; font-size: 12px; white-space: normal; background-color: rgb(255, 255, 255); margin-bottom: 10px;">
    <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.改进铝铸件 在铸型中的位置降低浇注温度和浇注速度</span>
</p>
<p>
    <br/>
</p>`,
    createdTime: '2019-09-07'
  }
]

export const FAQs = [
  {
    id: 1,
    question: '1. 冲压冲件高低（一模多件时）及对策',
    answer: `<p>原因: a、冲件毛边；b、冲件有压伤，模内有屑料；c、凸、凹模（折弯位）压损或损伤；d、冲剪时翻料；e、相关压料部位磨损、压损；f、相关撕切位撕切尺寸不一致，刀口磨损; g、相关易断位预切深度不一致，凸凹模有磨损或崩刃; h、相关打凸部位凸凹模有崩刃或磨损较为严重; i、模具设计缺陷。</p>
    <p>对策: a、研修下料位刀口; b、清理模具，解决屑料上浮问题；c、重新研修或更换新件；d、研修冲切刀口，调整或增设强压功能；e、检查，实施维护或更换；f、维修或更换，保证撕切状况一致; g、检查预切凸、凹模状况，实施维护或更换；h、检查凸、凹模状况，实施维护或更换；i、修改设计，加设高低调整或增设整形工位。</p>`
  },
  {
    id: 2,
    question: '2. 冲压件毛边及处理方法',
    answer: `<p>原因: a、刀口磨损； b、间隙过大研修刀口后效果不明显；c、刀口崩角; d、间隙不合理上下偏移或松动； e、模具上下错位。</p>
    <p>对策: a、研修刀口；b、控制凸凹模加工精度或修改设计间隙；c、研修刀口；d、调整冲裁间隙确认模板穴孔磨损或成型件加工精度等问题；e、更换导向件或重新组模。</p>`
  },
  {
    id: 3,
    question: '3. 压铸过程中金属液往外溅产生原因及处理方法',
    answer: `<p>产生原因: 1. 动，定模间合模不严密，间隙较大 2. 锁模力不够 3. 压铸机动，定模安装板不平行 4. 支板跨度大，压射力致使套板变形，产生喷料。</p>
    <p>调整方法: 1.重新安装模具 2.加大锁模力 3.调整压铸机，使动，定模安装板相互保持平行 4.在动模上增加支板，增加套板的刚度。</p>
    `
  },
  {
    id: 4,
    question: '4. 压铸件内有气孔产生及处理方法',
    answer: `<p>产生原因: 1.金属流动方向不正确，与铸件型腔发生正面冲击，产生涡流，将空气包围，产生气泡 2.内浇口太小，金属流速太大在空气未排除前，过早的堵住了排气孔，使气体留在了铸件内 3.型腔太深，通风排气困难 4.排气系统设计不合理，排气困难。</p>
    <p>调整方法: 1.修正分流锥大小及形状，防止造成与金属流对型腔的正面冲击 2.适当加大内浇口 3.改进模具设计 4.合理设计排气槽，增加空气穴。</p>
    `
  },
  {
    id: 5,
    question: '5. 压铸件内有气孔产生及处理方法',
    answer: `<p>产生原因: 1.金属流动方向不正确，与铸件型腔发生正面冲击，产生涡流，将空气包围，产生气泡 2.内浇口太小，金属流速太大在空气未排除前，过早的堵住了排气孔，使气体留在了铸件内 3.型腔太深，通风排气困难 4.排气系统设计不合理，排气困难。</p>
    <p>调整方法: 1.修正分流锥大小及形状，防止造成与金属流对型腔的正面冲击 2.适当加大内浇口 3.改进模具设计 4.合理设计排气槽，增加空气穴。</p>
    `
  }
]
