import React from 'react'
import { Form, Input, Button } from 'antd'

const Message = props => {
  const { form } = props
  const { getFieldDecorator } = form

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  }

  const buttonLayout = {
    wrapperCol: { span: 14, offset: 16 }
  }

  const handleSubmit = () => {}

  return (
    <div className="mt-16">
      <h2>在线留言</h2>
      <Form onSubmit={handleSubmit} layout="horizontal">
        <Form.Item label="标题" {...formItemLayout}>
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: '请输入标题！'
              }
            ]
          })(<Input placeholder="标题" />)}
        </Form.Item>
        <Form.Item label="内容" {...formItemLayout}>
          {getFieldDecorator('content', {
            rules: [
              {
                required: true,
                message: '请输入内容！'
              }
            ]
          })(<Input.TextArea placeholder="内容" rows={5} />)}
        </Form.Item>
        <Form.Item label="联系人" {...formItemLayout}>
          {getFieldDecorator('user', {
            rules: []
          })(<Input placeholder="请输入联系人" />)}
        </Form.Item>
        <Form.Item label="联系电话" {...formItemLayout}>
          {getFieldDecorator('phone', {
            rules: [
              {
                required: true,
                message: '请输入联系电话！'
              }
            ]
          })(<Input placeholder="联系电话" />)}
        </Form.Item>
        <Form.Item label="邮箱" {...formItemLayout}>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: '请输入正确的邮箱格式！'
              }
            ]
          })(<Input placeholder="邮箱" />)}
        </Form.Item>
        <Form.Item {...buttonLayout}>
          <Button type="primary">提交</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Form.create()(Message)
