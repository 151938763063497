import React, { useEffect, useState } from 'react'
import { Row, Col, List, Pagination } from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import '../News/News.less'
import { NEWS } from '../../dataSource'

export default function News() {
  const [data, setData] = useState([])
  useEffect(() => {
    const _temp = _.chunk(NEWS, 10)
    setData(_temp[0])
  }, [])

  const onChange = (page, size) => {
    const _temp = _.chunk(NEWS, 10)
    setData(_temp[page - 1])
  }

  return (
    <div className="mt-16">
      <h2>新闻</h2>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={
                <Link to={`/newsDetail/${item.id}`}>
                  <span>{item.title}</span>
                </Link>
              }
              description={item.intro}
            />

            <div>{item.createdTime}</div>
          </List.Item>
        )}
      />
      <div className="mt-16 mb-16">
        <Row type="flex" justify="start">
          <Col span={12}>
            <Pagination
              showQuickJumper
              defaultCurrent={1}
              total={NEWS.length}
              onChange={onChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
