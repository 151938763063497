import React from 'react'
import { Row, Col, Typography, Steps, Divider } from 'antd'

const { Title, Text } = Typography
const { Step } = Steps

export default function Culture() {
  return (
    <div className="mt-16">
      <Title level={3}>企业文化</Title>
      <Title level={4}>信誉始定，品质为先，不断创新，满意客户！</Title>
      <Title level={4}>
        做中国具有知名度的模具、零部件企业，所产的模具，零部件遍布全球。
      </Title>
      <Divider />
      <Row>
        <Col span={24}>
          <Title level={3}>质量保证的基本思路</Title>
          <p>
            遵守“客户至上”“质量至上”的大原则，秉承“质量于制造工序中打造”的精神，基于以下思路开展质量保证工作：
          </p>
          <p>
            <strong>
              1.在产品策划-设计-制造-检查，在进一步到市场活动质量评价的各个环节中，对保证产品质量开展的工作进行明确，并由各个部门按照规定实行质量保证工作；
            </strong>
          </p>
          <p>
            <strong>
              2.通过质量审核活动（体系，过程和产品审核）来确认各项质量保证活动是否有效实施，对产品和生产过程进行改进
            </strong>
          </p>
          <img
            style={{ width: '100%', margin: '1rem' }}
            src={require('../../assets/images/qualityProcess.jpg')}
            alt="质量体系"
          />
          <Divider />
          <Row type="flex" justify="space-between">
            <Col span={16}>
              <Title level={3}>通用文件管理</Title>
              <p>
                <strong>
                  1.标准类文件通过档案室分发，向全公司推广，并得到使用和管理；
                </strong>
              </p>
              <p>
                <strong>
                  2.文件修订遵从《文件管理规定》，制定-修改-发放-回收-废除。
                </strong>
              </p>
              <Title level={3}>质量文件管理</Title>
              <p>
                <strong>1.建立公司档案室，对图纸、技术规范；</strong>
              </p>
              <p>
                <strong>
                  2.SOP,SIP等生产和质量保证所需文件进行统一管理，文件修订亦遵从《文件管理规定》，制定-修改-发放-回收-废除；
                </strong>
              </p>
            </Col>
            <Col span={8}>
              <img
                style={{ width: '100%', margin: '1rem' }}
                src={require('../../assets/images/qualityDoc.jpg')}
                alt="质量体系"
              />
            </Col>
          </Row>
          <Divider />
          <Title level={3}>质量保证活动</Title>
          <Steps direction="vertical" current={7}>
            <Step
              title={<Title level={4}>产品策划</Title>}
              description={
                <Text strong>识别客户要求，策划新产品、制定合理的质量目标</Text>
              }
            />
            <Step
              title={<Title level={4}>产品设计</Title>}
              description={
                <Text strong>
                  依据国家法规、技术标准、现行产品质量和生产状况等因素，进行产品设计，经过试制、试验评价，制定设计质量要求
                </Text>
              }
            />
            <Step
              title={<Title level={4}>生产准备</Title>}
              description={
                <>
                  <Text strong>1.对每道工序的质量管控点予以明确</Text>
                  <br />
                  <Text strong>
                    2.使用实际量产设备进行试制，满足设计质量要求
                  </Text>
                  <br />
                  <Text strong>3.依据图纸和质量要求，明确检查项目</Text>
                </>
              }
            />
            <Step
              title={<Title level={4}>生产制造</Title>}
              description={
                <Text strong>
                  依据每道工序的质量特性进行检查，确认产品工序生产能力
                </Text>
              }
            />
            <Step
              title={<Title level={4}>采购</Title>}
              description={
                <Text strong>
                  保证采购件的质量，加强供方的质量管理，并协助和指导
                </Text>
              }
            />
            <Step
              title={<Title level={4}>产品检查</Title>}
              description={
                <Text strong>
                  验证生产质量和设计要求的符合性进行检查，采取一系列的产品质量保证活动
                </Text>
              }
            />
          </Steps>
          <Divider />
          <Title level={3}>IATF质量体系</Title>
          <img
            style={{ width: '100%', height: '70%', margin: '1rem' }}
            src={require('../../assets/images/quality.jpg')}
            alt="质量体系"
          />
        </Col>
      </Row>
    </div>
  )
}
