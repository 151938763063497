import React from 'react'
import { Row, Col, Card } from 'antd'
import _ from 'lodash'

const { Meta } = Card

export default function FactoryPhoto() {
  let images = []
  require
    .context('../../assets/factory', false, /\.jpg$/)
    .keys()
    .forEach(item => {
      images.push({
        url: item.replace('./', ''),
        name: item.substring(2, item.length - 4),
        namePrefix: item.substring(2, item.length - 4).split(' ')[0],
        index: Number(item.substring(2, item.length - 4).split(' ')[1])
      })
    })
  images = _.orderBy(images, ['namePrefix', 'index'])

  return (
    <div className="mt-16">
      <h1>公司场地实景</h1>
      <Row>
        {images.map((image, idx) => {
          return (
            <Col span={8} className="mt-16 mb-16" key={idx}>
              <Card
                hoverable
                style={{ width: 350 }}
                cover={
                  <img
                    style={{ maxWidth: '100%' }}
                    height="400"
                    alt={image.name}
                    src={require(`../../assets/factory/${image.url}`)}
                  />
                }>
                <Meta title={image.name} />
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}
