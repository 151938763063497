import React from 'react'
import {} from 'antd'

const ProductDetail = props => {
  const { location } = props
  const { query } = location
  return (
    <div className="mt-16 mb-16">
      {query && query.current && query.image && (
        <>
          <h1>{query.image.name}</h1>
          <img
            width="700"
            height="600"
            src={require(`../../assets/products/${query.current}/${query.image.url}`)}
            alt={query.image.name}
          />
        </>
      )}
    </div>
  )
}

export default ProductDetail
