import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import BasicLayout from './layout/index'
import './style/common.less'

function App() {
  return (
    <div className="App">
      <Router>
        <ConfigProvider locale={zhCN}>
          <BasicLayout />
        </ConfigProvider>
      </Router>
    </div>
  )
}

export default App
