import React, { useState, useEffect } from 'react'
import { Menu, Row, Col, Card, Pagination } from 'antd'
import _ from 'lodash'

const { Meta } = Card

export default function Product(props) {
  const { history } = props
  const [current, setCurrent] = useState('JiaDian')
  const [images, setImages] = useState([])
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const getImages = key => {
    let _images = []
    switch (key) {
      // case 'XiaoZhou':
      //   require
      //     .context('../../assets/products/XiaoZhou', false, /\.jpg$/)
      //     .keys()
      //     .forEach(item => {
      //       _images.push({
      //         url: item.replace('./', ''),
      //         name: item.substring(2, item.length - 4)
      //       })
      //     })
      //   break
      case 'DianJi':
        require
          .context('../../assets/products/DianJi', false, /\.jpg$/)
          .keys()
          .forEach((item, idx) => {
            _images.push({
              url: item.replace('./', ''),
              name: item.substring(2, item.length - 4),
              namePrefix: item.substring(2, item.length - 4).split(' ')[0],
              index: Number(item.substring(2, item.length - 4).split(' ')[1])
            })
          })
        break
      case 'DengJu':
        require
          .context('../../assets/products/DengJu', false, /\.jpg$/)
          .keys()
          .forEach(item => {
            _images.push({
              url: item.replace('./', ''),
              name: item.substring(2, item.length - 4),
              namePrefix: item.substring(2, item.length - 4).split(' ')[0],
              index: Number(item.substring(2, item.length - 4).split(' ')[1])
            })
          })
        break
      case 'JiaDian':
        require
          .context('../../assets/products/JiaDian', false, /\.jpg$/)
          .keys()
          .forEach(item => {
            _images.push({
              url: item.replace('./', ''),
              name: item.substring(2, item.length - 4),
              namePrefix: item.substring(2, item.length - 4).split(' ')[0],
              index: Number(item.substring(2, item.length - 4).split(' ')[1])
            })
          })
        break
      case 'QiChe':
        require
          .context('../../assets/products/QiChe', false, /\.jpg$/)
          .keys()
          .forEach(item => {
            _images.push({
              url: item.replace('./', ''),
              name: item.substring(2, item.length - 4),
              namePrefix: item.substring(2, item.length - 4).split(' ')[0],
              index: Number(item.substring(2, item.length - 4).split(' ')[1])
            })
          })
        break
      // case 'QiCheHouShiJing':
      //   require
      //     .context('../../assets/products/QiCheHouShiJing', false, /\.jpg$/)
      //     .keys()
      //     .forEach(item => {
      //       _images.push({
      //         url: item.replace('./', ''),
      //         name: item.substring(2, item.length - 4)
      //       })
      //     })
      //   break
      default:
        return null
    }
    const temp = _.sortBy(_images, 'namePrefix', function(img) {
      return img.index
    })
    setImages(temp)
    const _temp = _.chunk(temp, 9)
    setData(_temp[0])
  }

  const handleClickCard = (image, current) => {
    const { history } = props
    history.push({ pathname: '/productDetail', query: { current, image } })
  }

  const handleClick = e => {
    setCurrentPage(1)
    setCurrent(e.key)
    getImages(e.key)
  }

  const onChange = (page, size) => {
    const _temp = _.chunk(images, 9)
    setData(_temp[page - 1])
    setCurrentPage(page)
  }

  useEffect(() => {
    let images = []
    require
      .context('../../assets/products/JiaDian', false, /\.jpg$/)
      .keys()
      .forEach(item => {
        images.push({
          url: item.replace('./', ''),
          name: item.substring(2, item.length - 4),
          namePrefix: item.substring(2, item.length - 4).split(' ')[0],
          index: Number(item.substring(2, item.length - 4).split(' ')[1])
        })
      })
    const temp = _.sortBy(images, 'namePrefix', function(img) {
      return img.index
    })
    setImages(temp)
    const _temp = _.chunk(temp, 9)
    setData(_temp[0])
  }, [history])

  useEffect(() => {
    const pathname = history.location.pathname
    const type = pathname.substring(9, pathname.length)
    // handlePathChange(type)
    setCurrentPage(1)
    setCurrent(type)
    getImages(type)
  }, [history.location.pathname])

  // getImages('JiDian')

  return (
    <div className="mt-16">
      <h1>产品展示</h1>
      <Row>
        <Col span={6}>
          <Menu
            onClick={handleClick}
            selectedKeys={[current]}
            mode="inline"
            style={{ width: 256 }}>
            {/* <Menu.Item key="XiaoZhou">销轴</Menu.Item> */}
            <Menu.Item key="DianJi">电机铸件类</Menu.Item>
            <Menu.Item key="JiaDian">家电铸件类</Menu.Item>
            <Menu.Item key="QiChe">汽车铸件类</Menu.Item>
            {/* <Menu.Item key="QiCheHouShiJing">汽车后视镜支座</Menu.Item> */}
            <Menu.Item key="DengJu">灯具</Menu.Item>
          </Menu>
        </Col>
        <Col span={18}>
          <Row gutter={32}>
            {data.length > 0 &&
              data.map((image, idx) => {
                return (
                  <Col span={8} className="mt-16 mb-16" key={idx}>
                    <Card
                      onClick={() => handleClickCard(image, current)}
                      hoverable
                      style={{ width: 300 }}
                      cover={
                        <img
                          height="300"
                          alt={image.name}
                          src={require(`../../assets/products/${current}/${image.url}`)}
                        />
                      }>
                      <Meta title={image.name} />
                    </Card>
                  </Col>
                )
              })}
            {images.length === 0 && <span>暂无此分类</span>}
          </Row>
          <Row className="mb-16" type="flex" justify="end">
            <Pagination
              showQuickJumper
              current={currentPage}
              total={images.length}
              onChange={onChange}
            />
          </Row>
        </Col>
      </Row>
    </div>
  )
}
