import React from 'react'
import { Row, Col } from 'antd'

export default function About() {
  return (
    <div className="mt-16">
      <h2>我们的规模</h2>
      <Row gutter={16} type="flex" align="middle" justify="center">
        <Col span={16}>
          <img
            src={require('../../assets/images/factory.jpeg')}
            alt="厂房"
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8}>
          <h2>公司成立于2007年</h2>
          <h2>新建厂房占地面积 10550平方米</h2>
        </Col>
      </Row>
      <h2 className="mt-16">关于我们</h2>
      {/* <Row gutter={16} type="flex" align="middle" justify="center">
        <Col span={24}>
          <img
            src={require('../../assets/images/lobby.png')}
            alt="大堂"
            style={{ width: '100%' }}
          />
        </Col>
      </Row> */}
      <Row>
        <Col span={24}>
          <img
            src={require('../../assets/images/lobby.png')}
            alt="大堂"
            style={{ width: '40%', float: 'left', margin: '1rem' }}
          />
          <div className="intro-text">
            <p>
              &nbsp;&nbsp;
              &nbsp;无锡市汇源机械科技有限公司地处经济和工业快速发展的无锡市锡山区锡北镇工业园区，周边交通物流便捷,有无锡硕放苏南国际机场、京沪高铁无锡东站和京沪高速。
            </p>
            <p>
              &nbsp; &nbsp; &nbsp;公司成立于2007
              年，注册资本为人民币1650万，专业从事铝合金、锌合金压铸件、冲压件的模具开发和后续生产。目前拥有国内压铸第一品牌力劲锌合金压铸机88吨2台；力劲铝合金压铸机6台（280吨-630吨）;气动冲床生产线壹条（8台：250吨-110吨）；检测设备有:德国进口牛津光谱分析仪、X
              光探伤机、三座标测量仪及万能拉力机等;后道加工设备有:数控车床10台、CNC加工中心10台；全自动喷粉流水线壹条；半自动电泳线壹条。
            </p>
            <p>
              &nbsp; &nbsp;
              &nbsp;主要产品为汽车零部件、电机配件和家电类产品等。主要客户有无锡明芳、无锡爱信、无锡裕昌、福州明芳、无锡麻德克斯、上海吕巷、上海瑞尔、北京光华荣昌等。
            </p>
            <p>
              &nbsp; &nbsp;
              企业现有员工60余人，厂房占地面积10550平方米，建筑面积15000平方米。2012年2月公司通过ISO9001:2008体系认证，2015年9月份公司通过ISO/TS
              16949:2009体系认证，2019年10月份公司通过公司IATF16949:2016体系认证。
            </p>
            <p>&nbsp;&nbsp;</p>
            <p>
              &nbsp; &nbsp;
              公司秉承“信誉始立，品质为先，不断创新，满意客户”的经营理念携手团队与您共创辉煌。
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
}
