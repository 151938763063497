import React from 'react'
import { NEWS } from '../../dataSource'
import './News.less'

export default function NewsDetail(props) {
  const id = props.match.params.id
  const news = NEWS.find(news => news.id === id)

  return (
    <div className="mt-16 mb-16 news-detail">
      <p className="title">{news.title}</p>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: news.content }}></div>
    </div>
  )
}
