import React from 'react'
import { Row, Col, Card, Button, Divider, Typography, Steps } from 'antd'
import Slider from 'react-slick'
import './Home.less'

const { Meta } = Card
const { Title } = Typography
const { Step } = Steps

export default function Home(props) {
  const { history } = props
  const showMoreAboutUs = () => {
    history.push('/address')
  }
  const images = []
  require
    .context('../assets/products/Home', false, /\.jpg$/)
    .keys()
    .forEach((item) => {
      images.push({
        url: item.replace('./', ''),
        name: item.substring(2, item.length - 4),
      })
    })

  const goToProduct = (e, image) => {
    e.preventDefault()
    let productType = 'JiaDian'
    switch (image.name) {
      // case '销轴':
      //   productType = 'XiaoZhou'
      //   break
      case '家电铸件':
        productType = 'JiaDian'
        break
      // case '汽车后视镜支座':
      //   productType = 'QiCheHouShiJing'
      //   break
      case '汽车铸件':
        productType = 'QiChe'
        break
      case '灯具':
        productType = 'DengJu'
        break
      case '电机铸件':
        productType = 'DianJi'
        break
      default:
        productType = 'JiaDian'
    }
    history.push(`/product/${productType}`)
  }

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    className: 'center',
    infinite: true,
    slidesToShow: 4,
    speed: 500,
  }
  return (
    <div className="mt-16 home">
      <Row type="flex" justify="center">
        <Col span={4}>
          <h1>产品展示</h1>
        </Col>
      </Row>
      <Row gutter={32}>
        {images.length > 0 &&
          images.map((image, idx) => {
            return (
              <Col span={6} className="mt-16 mb-16" key={idx}>
                <Card
                  hoverable
                  style={{ width: 300 }}
                  onClick={(e) => goToProduct(e, image)}
                  cover={
                    <img
                      height="300"
                      alt={image.name}
                      src={require(`../assets/products/Home/${image.url}`)}
                    />
                  }>
                  <Meta title={image.name} />
                </Card>
              </Col>
            )
          })}
      </Row>
      <section className="cert">
        <div className="cert-header">
          <div>无锡市汇源机械科技有限公司</div>
          <Divider type="vertical" />
          <div className="cert-header-intro">
            专业从事铝合金，锌合金压铸件的模具开发、压铸及加工
          </div>
        </div>
        <div className="cert-container">
          <Row className="mt-16 mb-16" type="flex" justify="center">
            <Col span={20}>
              <Title level={4}>
                公司成立于2007年，注册资本为人民币1650万，专业从事铝合金、锌合金压铸件的模具开发、压铸及加工
              </Title>
            </Col>
          </Row>
          <Row gutter={64} type="flex" align="middle">
            <Col span={12}>
              <img
                className="image-center"
                src={require('../assets/images/cert.jpg')}
                alt="奖项"
              />
            </Col>
            <Col span={12}>
              <Steps direction="vertical" current={4}>
                <Step
                  title="一站式全方位服务"
                  description="公司成立于2007年，注册资本为人民币1650万，专业从事铝合金、锌合金压铸件的模具开发、压铸及加工"
                />
                <Step
                  title="强大的产能及精密生产设备"
                  description="目前拥有国内知名品牌力劲压铸机200吨1台、280吨2台、400吨2台、500吨1台"
                />
                <Step
                  title="多年行业经验以及拥有专业的技术团队"
                  description="企业现有员工50余人，新建厂房占地面积10550平方米，建筑面积15000平方米。2012年2月公司通过ISO9001:2008体系认证，2015年9月份公司通过ISO/TS 16949:2009体系认证"
                />
                <Step
                  title="售后服务保障"
                  description="公司秉承“信誉始立，品质为先，不断创新，满意客户”的经营理念携手团队与您共创辉煌"
                />
              </Steps>
            </Col>
          </Row>
        </div>
      </section>
      <section className="cert mt-16">
        <div className="cert-header">
          <div>我们的客户</div>
          <Divider type="vertical" />
          <div className="cert-header-intro">
            专业从事铝合金，锌合金压铸件的模具开发、压铸及加工
          </div>
        </div>
        <div className="cert-container">
          <Row className="mt-16 mb-16" type="flex" justify="center">
            <Slider {...settings} style={{ width: '100%' }}>
              {[
                '无锡明芳   ',
                '上海汽车   ',
                '福州明芳   ',
                '上海瑞尔   ',
                '无锡爱信   ',
                '无锡麻德克斯',
                '麦格纳     ',
                '上海申池   ',
                '延峰安道拓  ',
              ].map((customer, idx) => {
                return <Title key={idx}>{customer}</Title>
              })}
            </Slider>
          </Row>
        </div>
      </section>
      <section className="aboutus">
        <div className="home-about-container">
          <Row gutter={32}>
            <Col span={12}>
              <img
                className="image-center"
                src={require('../assets/images/about.png')}
                alt="团队"
              />
            </Col>
            <Col span={12}>
              <div>
                <p className="aboutus-us">关于我们</p>
                <p>
                  无锡市汇源机械科技有限公司地处经济和工业快速发展的无锡市锡山区锡北镇工业园区，周边交通物流便捷,有无锡硕放苏南国际机场、京沪高铁无锡东站和京沪高速。
                  公司成立于2007
                  年，注册资本为人民币1650万，专业从事铝合金、锌合金压铸件、冲压件的模具开发和后续生产。目前拥有国内压铸第一品牌力劲锌合金压铸机88吨2台；力劲铝合金压铸机6台（280吨-630吨）;气动冲床生产线壹条（8台：250吨-110吨）；检测设备有:德国进口牛津光谱分析仪、X
                  光探伤机、三座标测量仪及万能拉力机等;后道加工设备有:数控车床10台、CNC加工中心10台；全自动喷粉流水线壹条；半自动电泳线壹条。
                  主要产品为汽车零部件、电机配件和家电类产品等。主要客户有无锡明芳、无锡爱信、无锡裕昌、福州明芳、无锡麻德克斯、上海吕巷、上海瑞尔、北京光华荣昌等。
                  企业现有员工60余人，厂房占地面积10550平方米，建筑面积15000平方米。2012年2月公司通过ISO9001:2008体系认证，2015年9月份公司通过ISO/TS
                  16949:2009体系认证，2019年10月份公司通过公司IATF16949:2016体系认证。
                  公司秉承“信誉始立，品质为先，不断创新，满意客户”的经营理念携手团队与您共创辉煌。
                </p>
                <p>
                  主要产品为汽车零部件、电机配件和家电类产品等。主要客户有无锡明芳、无锡爱信、无锡裕昌、福州明芳、无锡麻德克斯、上海吕巷、上海瑞尔、北京光华荣昌等。企业现有员工60余人，厂房占地面积10550平方米，建筑面积15000平方米。2012年2月公司通过ISO9001:2008体系认证，2015年9月份公司通过ISO/TS
                  16949:2009体系认证，2019年10月份公司通过公司IATF16949:2016体系认证。
                  公司秉承“信誉始立，品质为先，不断创新，满意客户”的经营理念携手团队与您共创辉煌。
                </p>
                <p>
                  <Button type="default" onClick={showMoreAboutUs}>
                    了解更多
                  </Button>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  )
}
